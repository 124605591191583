import { template as template_06ad14d5c0634c2b9620568f7c909f04 } from "@ember/template-compiler";
const FKControlMenuContainer = template_06ad14d5c0634c2b9620568f7c909f04(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
