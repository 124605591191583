import { template as template_a9e846f32e4846e6bf9714b43a5e52cd } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_a9e846f32e4846e6bf9714b43a5e52cd(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
