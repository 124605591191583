import { template as template_e3c3e17ec4d44ea68390f1d7dc8c8e1c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e3c3e17ec4d44ea68390f1d7dc8c8e1c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
