import { template as template_349691d4ac3344a8830ca0bb52ba5424 } from "@ember/template-compiler";
const FKLabel = template_349691d4ac3344a8830ca0bb52ba5424(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
